import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { getFirestore, collection, addDoc, getDocs, doc, setDoc  } from "firebase/firestore";
import { firebase } from ".";


function Room() {
  const { roomId } = useParams();
  const location = useLocation();
  const { name, role } = location.state || {name: "Guest", role:"Audience"};

  const [question, setQuestion] = useState("");
  const [multichoice, setMultichoice] = useState("");

  const [data, setData] = useState([
    {
      'question': '',
      'multichoice': [],
      'answers': [],
      'users': 0
    }
  ]);

  const questionnaire = {
    'question': '',
    'multichoice': [],
    'answers': [],
    'users': 0
  }

  function randomID(len) {
    let result = "";
    if (result) return result;
    var chars =
        "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
  }

  const roleCondition =
    role === "Host"
      ? ZegoUIKitPrebuilt.Host
      : //   : role_str === "Cohost"
        //   ? ZegoUIKitPrebuilt.Cohost
        ZegoUIKitPrebuilt.Audience;

  const sharedLinks = [
    {
      name: "Join as Audience",
      url: `${window.location.origin}/room/${roomId}`,
    },
  ];

  const appID = 1509601827;
  const serverSecret = "a4237276a7b06de72614808f7cd9ff42";
  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
    appID,
    serverSecret,
    roomId,
    randomID(5),
    name
  );

  let myMeeting = async (element) => {
    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
         role:  roleCondition,
        },
      },
      sharedLinks,
    });
  };

  const addQuestion = () => {
    let dataset = data;
    const d = dataset.at(-1);
    d.question = question;

    dataset.pop();
    dataset.push(d);

    setData(dataset);

    setQuestion('');

    console.log('dataset',data);
  }

  const addMultichoice = () => {
    let dataset = data;
    const d = dataset.at(-1);
    let tmpMultiChoice = d.multichoice;
    tmpMultiChoice.push(multichoice);
    d.multichoice = tmpMultiChoice;

    dataset.pop();
    dataset.push(d);

    setData(dataset);

    setMultichoice('');

    console.log('dataset',data);
  }

  const submitForm =async (e) => {
    e.preventDefault();  

    let dataset1 = data;

    const d = dataset1.at(-1);

    const db = getFirestore(firebase);
    
    try {
      const docRef = doc(db, "live", `${roomId}` );
      setDoc(docRef, d)
      .then(() => {
      setData([questionnaire]);
  })
.catch(error => {
})} catch (e) {}
}

  return (
    <div className="flex flex-row">
      <div
        className="grow"
        ref={myMeeting}
       // style={{ width: "100vw", height: "100vh" }}
      ></div>
      <div className="w-96 flex flex-col">
       <div className="mt-4">
         <h1>Form</h1>
         {/* Add Question */}
         { data.at(-1).question === '' && <h1>Question</h1> }
         { data.at(-1).question === '' && <div class="w-full max-w-sm min-w-[200px]">
  <input 
    value={question}
    onChange={(e) => setQuestion(e.target.value)}
    class="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" placeholder="Type here..."/>
</div> }
         { data.at(-1).question === '' && <button className="mt-2 rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ml-2" type="submit" onClick={addQuestion}>Add Question</button> }


{/* Add MultiChoice Question */}
         {  data.at(-1).question !== '' && data.at(-1).multichoice.length !== 3 && <h1>Multichoice Answers</h1> }
         {
              <ul>
                {data.at(-1).multichoice.map((c) => <h1>{c}</h1> )}
              </ul>
         }
         {  data.at(-1).question !== '' && data.at(-1).multichoice.length !== 3 && <div class="w-full max-w-sm min-w-[200px]">
  <input 
    value={multichoice}
    onChange={(e) => setMultichoice(e.target.value)}
    class="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" placeholder="Type here..."/>
</div> }
         {  data.at(-1).question !== '' && data.at(-1).multichoice.length !== 3 && <button className="mt-2 rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ml-2" type="submit" onClick={addMultichoice}>Add MultiChoice Answer</button> }



    {/* Submit Button */}
         { data.at(-1).question !== '' &&  data.at(-1).multichoice.length === 3 &&<button className="mt-2 rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ml-2" type="submit" onClick={submitForm}>Submit Question and MultiChoice</button>  }
       </div>
      </div>
    </div>
  );
}

export default Room; 