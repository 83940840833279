import './App.css';
import './output.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home';
import Host from './host';
import Room from "./room";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/host" element={< Host />} />
          <Route path="/room/:roomId" element={<Room></Room>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
