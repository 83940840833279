import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { firebase } from ".";
import { getFirestore, collection, addDoc, getDocs  } from "firebase/firestore";

function Host() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchPost = async () => {

    const db = getFirestore(firebase);

    await getDocs(collection(db, "live"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
                setData(newData);                
            console.log('data', newData);
    })
}

  useEffect(() => {
  fetchPost();
  }, []);


  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const startLiveStream = () => {
    // Simulate host starting the stream (backend/API would track this)
    sessionStorage.setItem(`room_${9}_live`, "true");

    const rndInt = randomIntFromInterval(1, 100);

    navigate(`/room/${rndInt}`, {
      state: { host: true, role: "Host" , name: "TV Host"},
    });
  };

  return (
    <div>
      <h1 class="text-4xl font-bold underline">
      Start a Live Stream
  </h1>
      {/* <input@if (condition) 
        
      @else 
      
      @endif
        type="text"
        placeholder="Enter room ID"
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
      /> */}
      <br />
      <button onClick={startLiveStream}>Start Stream</button>
    </div>
  );
}

export default Host;
