import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDTdFjCLDRtvbJSeWr8bzFmrkeDnKWIS7U",
  authDomain: "cheza-kumi.firebaseapp.com",
  projectId: "cheza-kumi",
  storageBucket: "cheza-kumi.firebasestorage.app",
  messagingSenderId: "574040909303",
  appId: "1:574040909303:web:bf6bc1cdeb6670234a7af2",
  measurementId: "G-8Q1CMG1YGG"
};

export const firebase = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
